import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/home/">Strona główna</a>
                </li>
                <li>
                  <a href="/produkty/">Produkty</a>
                </li>
                <li>
                  <a href="/poradnik/">Poradnik</a>
                </li>
                <li>
                  <a href="/dobierz-lek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/pytania-i-odpowiedzi/">Pytania i odpowiedzi</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Strona główna</a>
            <a href="/poradnik/#katar"> &gt; Poradnik(Katar)</a>
            <a
              href="/poradnik/katar/podejmij_szybka_walke_z_katarem/"
              className="sel"
            >
              {" "}
              &gt; Podejmij szybką walkę z katarem
            </a>
          </div>
          <div id="page_content">
            <div className="col1">
              <div className="main_photo">
                <img src="/web/kcfinder/uploads/files/shutterstock_152527238.jpg" />
              </div>
            </div>
            <div className="col2">
              <h1>Podejmij szybką walkę z katarem</h1>
              <div className="wysiwyg">
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <b>
                    Idąc do pracy spotykasz kilka kichających osób, koleżanka
                    zza biurka nie „odrywa” się od paczki chusteczek, a u
                    jednego z domowników pojawia się uczucie drapania w gardle,
                    osłabienie i rozdrażnienie. Nie masz szans! Kontakt z
                    wirusami powoduje, że po dwóch dniach nieżyt nosa dopada
                    także Ciebie. Jak łagodzić objawy kataru? Czym je leczyć?
                    Istnieje kilka sprawdzonych metod, które warto zastosować.
                  </b>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    Katar jest zaraźliwy. Wirusy przedostają się do zdrowego
                    organizmu drogą powietrzno-kropelkową. Wystarczy, że chory
                    znajdzie się w tym samym pomieszczeniu, co Ty lub był w nim
                    wcześniej. Wówczas dostrzegasz u siebie symptomy, takie jak:
                    drapanie w nosie, ogólne osłabienie, rozdrażnienie.
                    Następnie rozszerzają się naczynia krwionośne, pojawia się{" "}
                    <a
                      href="/slowniczek/#obrzek_blony_sluzowej"
                      target="_blank"
                    >
                      obrzęk błony śluzowej nosa
                    </a>{" "}
                    i zaczynasz mieć problemy z oddychaniem przez nos. Utrudnia
                    to funkcjonowanie i wykonywanie podstawowych czynności w
                    ciągu dnia oraz źle wpływa na zasypianie i spokojny sen w
                    nocy. W walce z wirusami mogą pomóc inhalacje z naparu z
                    rumianku, gorąca kąpiel czy herbata z miodem i imbirem.
                    Podczas pierwszych dni infekcji należy zostać w domu, by
                    szybko pozbyć się „intruzów” z organizmu. Warto pamiętać, że
                    na początku choroby jesteśmy „nosicielami zarazków” i
                    najszybciej możemy kogoś zarazić.&nbsp;
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <img
                    alt="SUDAFED® sposób na zatkany nos."
                    src="/web/kcfinder/uploads/images/shutterstock_1097978291.jpg"
                    style={{
                      height: "200px",
                      float: "right",
                      margin: "5px 0px 10px 10px",
                      "border-width": "2px",
                      "border-style": "solid",
                      width: "300px",
                    }}
                  />
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <div>&nbsp;</div>
                <div
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  Sprawdzonym domowym sposobem na katar jest rozgrzanie stóp
                  gorącą wodą z solą kuchenną oraz kąpiel z dodatkiem olejku
                  miętowego lub eukaliptusowego. Równie istotny dla wzmocnienia
                  organizmu jest odpoczynek. Przed snem warto natrzeć stopy
                  maścią kamforową, założyć wełniane, grube skarpety i owinąć
                  się dodatkowym kocem, by wypocić się w nocy. Niezbędne jest
                  również zaopatrzenie się w preparaty, które pomogą odblokować
                  nos i złagodzić objawy kataru.{" "}
                  <a href="/produkty/sudafed_tabletki/" target="_blank">
                    Sudafed<sup>®</sup> tabletki
                  </a>
                  , dzięki zawartości pseudoefedryny, zmniejszają obrzęk i
                  usuwają zalegającą w nosie{" "}
                  <a href="/slowniczek/#wydzielina_z_nosa" target="_blank">
                    wydzielinę
                  </a>
                  , tym samym eliminując uczucie ucisku w głowie i zatokach
                  <sup>1</sup>. Z kolei aerozol do nosa, np.{" "}
                  <a href="/produkty/sudafed_xylospray_ha/" target="_blank">
                    Sudafed<sup>®</sup> XyloSpray HA
                  </a>
                  , dzięki zawartości{" "}
                  <a href="/slowniczek/#ksylometazolina" target="_blank">
                    ksylometazoliny
                  </a>
                  , odblokowuje nos oraz nie podrażnia błon śluzowych, gdyż nie
                  zawiera konserwantów. Co więcej, jako jedyny<sup>2</sup>{" "}
                  posiada aż trzy substancje nawilżające nos:{" "}
                  <a href="/slowniczek/#glicerol" target="_blank">
                    glicerol
                  </a>
                  ,{" "}
                  <a href="/slowniczek/#sorbitol" target="_blank">
                    sorbitol
                  </a>{" "}
                  i{" "}
                  <a href="/slowniczek/#kwas_hialuronowy" target="_blank">
                    kwas hialuronowy
                  </a>{" "}
                  (w postaci soli sodowej).
                </div>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    Podczas kataru należy pamiętać o właściwej higienie jamy
                    nosowej i stosowaniu mydła antybakteryjnego do mycia rąk. Do
                    czyszczenia nosa najlepiej używać miękkich, jednorazowych
                    chusteczek, a do złagodzenia podrażnień skóry wokół nosa –
                    kremów nawilżających. By wzmocnić organizm niezbędne jest
                    regularne jedzenie owoców i warzyw, które dostarczają wielu
                    witamin (np. jabłka, cytryny, pomidory i ziemniaki zawierają
                    duże ilości witaminy C; grejpfruty, arbuzy i brzoskwinie są
                    bogate w witaminę A). Warto również zapobiegać
                    rozprzestrzenianiu się chorobotwórczych drobnoustrojów,
                    sprzątając mieszkanie nie tylko środkami czyszczącymi, ale i
                    takimi, które zabijają bakterie.
                  </span>
                </p>
                <p
                  className="p4"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <div>
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    1 Dotyczy uczucia ucisku w zatokach spowodowanego
                    nagromadzoną wydzieliną i obrzękiem błony śluzowej
                    nosa.&nbsp;
                  </span>
                </div>
                <div>
                  <span
                    style={{
                      "font-size": "11px",
                      display: "inline-block",
                      "line-height": "normal",
                    }}
                  >
                    2 Wśród produktów zawierających Xylometazolinę/Oxymetazolinę
                    w postaci aerozolu do nosa, dopuszczonych do obrotu w Polsce
                    według Urzędowego Wykazu Produktów Leczniczych Dopuszczonych
                    do Obrotu na terytorium Rzeczypospolitej Polskiej, według
                    stanu na dzień 1 Lutego 2023 r.
                  </span>
                </div>
                <div>&nbsp;</div>
                <p
                  className="p5"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    <span className="s1">Bibliografia:&nbsp;</span>
                  </span>
                </p>
                <p
                  className="p5"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    <span className="s1">
                      P. Rapiejko, A. Lipiec, <i>Podział nieżytów nosa</i>, [w:]
                      „Alergia”, 2013, nr 2, s. 6-8.
                    </span>
                  </span>
                </p>
              </div>
              <div className="other_article">
                <p className="name">Podobne artykuły</p>
                <a
                  href="/poradnik/katar/jak_prawidlowo_oczyszczac_nos_podczas_kataru"
                  className="box"
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/artykuly/shutterstock_300834140.jpg")',
                    }}
                  />
                  <span className="title">
                    Jak prawidłowo oczyszczać nos podczas kataru?
                  </span>
                </a>
                <a href="/poradnik/katar/poznaj_rodzaje_kataru" className="box">
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/artykuly/shutterstock_388715146.jpg")',
                    }}
                  />
                  <span className="title">Poznaj rodzaje kataru</span>
                </a>
                <a
                  href="/poradnik/katar/katar_alergiczny_-_poznaj_i_zapobiegaj"
                  className="box"
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/shutterstock_1206612040.jpg")',
                    }}
                  />
                  <span className="title">
                    Katar alergiczny - poznaj i zapobiegaj
                  </span>
                </a>
              </div>
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <footer>
          <div className="page_width">
            <div className="drug_alert"></div>
            <p
              style={{
                "font-size": "10px",
                "-webkit-text-align": "right",
                "text-align": "right",
                padding: "0 0 10px",
              }}
            >
              MAT/6769/03/2019
            </p>
          </div>
          <div className="footer_content">
            <div className="page_width">
              <ul id="footer_menu">
                <li>
                  <a href="/polityka-prywatnosci/">Polityka prywatności</a>
                </li>
                <li>
                  <a href="/polityka-cookies/">Polityka cookies</a>
                </li>
                <li>
                  <a href="/nota-prawna/">Nota prawna</a>
                </li>
                <li>
                  <a href="/mapa-strony/">Mapa strony</a>
                </li>
                <li>
                  <a href="/slowniczek/">Słowniczek</a>
                </li>
                <li>
                  <a
                    id="ot-sdk-btn"
                    className="ot-sdk-show-settings"
                    rel="nofollow"
                  >
                    {" "}
                    Ustawienia plików Cookie{" "}
                  </a>
                </li>
              </ul>
              <div className="rel footer_info">
                <br />
                Podmiot odpowiedzialny: McNeil Products Limited <br />© JNTL Consumer Health (Poland) sp. z o.o. 2023<br />
                JNTL Consumer Health (Poland) sp. z o.o. w ramach swojej działalności gospodarczej prowadzi hurtowy obrót produktami leczniczymi. Jeżeli jesteś przedsiębiorcą prowadzącym aptekę ogólnodostępną i jesteś zainteresowany nabyciem produktów leczniczych oferowanych przez nas, uprzejma prośba o kontakt z działem Customer Service email: <a href="mailto:apteki@kenvue.com">apteki@kenvue.com</a>. Minimalna wartość zamówienia 500 PLN brutto. Zamówienia realizowane będą wyłącznie po otrzymaniu pełnej przedpłaty. 2023
              </div>
            </div>
          </div>
        </footer>{" "}
      </div>
    );
  }
}

export default Page;
